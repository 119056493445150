import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { SyncLoader } from "react-spinners"
import { BASE_URL, PURCHASE_METHODS, networkLinks } from "../../_constant"
import { getSubsDetailsAction } from "../../redux/apiActions/api.action"
import { useAccount } from "wagmi"
import queryString from "query-string";
import moment from "moment"
import { bsc, bscTestnet } from "viem/chains"

export default function SubsDetails (){
    const [loading,setLoading] = useState(false)

    const { search } = window.location;
    const { id } = queryString.parse(search);
    const {address} = useAccount()
    const [details,setDetails] = useState({})
    const getSubsDetails = async()=>{
        try{
            setLoading(true)
            let bodyData = {address:address,id:id}
            const {data} = await getSubsDetailsAction(bodyData)
            setDetails(data?.data)
        }catch(err){

        }finally{
            setLoading(false)
        }
    

    }

    useEffect(()=>{
        getSubsDetails()
    },[])

    const navigate = useNavigate()
    return(
        <div className="container-fluid px-lg-4">

        {loading ? (
                <div className="d-flex justify-content-center align-items-center vh-100">
                <SyncLoader color="#3498DB" />
              </div>
        ) : (
          <>
           
              <>
            <div className="row mt-4">
            <div className="d-flex align-items-center tnbc_back_section">
                  {/* <Link to={BASE_URL}>
                  <i className="fa fa-arrow-left" aria-hidden="true" />
                </Link> */}
                  <a
                    onClick={() => {
                      { navigate(-1) ? navigate(-1) : navigate(BASE_URL+'subsc-cashback') };
                    }}
                    // href={BASE_URL}
                    className="me-2"
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="fa fa-arrow-left" aria-hidden="true" />
                  </a>
                  <p className="mb-0">Back</p>
                </div>
            </div>
            <div className="container">
            <div className="row mt-4 justify-content-center">
                <div className="col-xxl-8 col-lg-8 col-md-12">
                <div className="tnbc_custom_card">
                    <div className="custom_card">
                    <div className="p-3">
                        <div style={{ position: 'relative' }} className="card_second_section d-flex justify-content-between align-items-center">
                              <h3>Subscription Details</h3>
                        </div>
                        <ul className="presale_address_ui mt-2">
                        {/* <li className="d-flex justify-content-between">
                            <p>Package</p>
                            <p>{details?.title}</p>
                          </li> */}
                        {/* <li className="d-flex justify-content-between">
                            <p>Payment mode</p>
                            <p>{ PURCHASE_METHODS.find(method => method.title.includes(details?.payment_mode))}</p>
                          </li> */}
                          {/* <li className="d-flex justify-content-between">
                            <p>Purchase date</p>
                            <p>{moment(details?.created_date).format('DD-MM-YYYY')}</p>
                          </li> */}
                          {/* <li className="d-flex justify-content-between">
                            <p>Valid date</p>
                            <p>{moment(details?.valid_date).format('DD-MM-YYYY')}</p>
                          </li> */}
                           <li className="d-flex justify-content-between">
                            <p>Subscription amount</p>
                            <p>{details?.price}</p>
                          </li>

                          <li className="d-flex justify-content-between">
                            <p>Duration	</p>
                            <p>{details?.duration} Days</p>
                          </li>
                         
                          <li className="d-flex justify-content-between">
                            <p>Paid amount</p>
                            <p>${Number(Number(details?.total_coin) * Number(details?.coin_price))?.toFixed(2)} </p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Transaction hash</p>
                            <p
                             onClick={() => {
                                window.open(
                                  `${networkLinks[bsc?.id]}/tx/${details?.hash}`  //DynamicChain
                                )
                              }}
                              style={{ cursor: 'pointer', color: '#3498DB', textDecoration: 'underline' }}
                            >{details?.hash}</p>
                          </li>

                          <li className="d-flex justify-content-between">
                            <p>Total amount	</p>
                            <p>{details?.total_amount}</p>
                          </li>
                          <li className="d-flex justify-content-between">
                            <p>Total coin</p>
                            <p>{Number(details?.total_coin).toFixed(6)} HLPR</p>
                          </li>

                          {/* <li className="d-flex justify-content-between">
                            <p>Coin price</p>
                            <p>{details?.coin_price}</p>
                          </li> */}

                       
                        </ul>
                    </div>
                    </div>
                </div>
                <div className="row">
                    {/* Additional content here */}
                </div>
                </div>
            </div>
            </div>

            </>
            
          </>
        )}
      </div>
    )
}