import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
// import * as userServices from '../../services/API/api.service'

export const userSlice = createSlice({
  name: 'users',
  initialState: {
    myCWallet:{account:'',chainId:"",isConnected:false},
    redrumConnectorId:'injected',
    redrumWallet:'',
    isHalf:false
  },
  reducers: {
    myCWallet(state,action){
      state.myCWallet = action.payload
    },
    redrumConnectorId(state,action){
      state.redrumConnectorId = action.payload
    },
    redrumWallet(state,action){
      state.redrumWallet = action.payload
    },
    search(state,action){
      state.search = action.payload
    },
    isHalf(state,action){
      state.isHalf = action.payload
    }
  },
})

export const {myCWallet,redrumConnectorId,redrumWallet, search,isHalf } = userSlice.actions

export default userSlice.reducer
