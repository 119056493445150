import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { checkReferrerAction, checkRegisterAction, getPackageListAction, sendOTPSubscAction, subscribeAction, subscriptionRegisterAction, usersLoginServieAction } from "../../redux/apiActions/api.action";
import { getAccount, getNetwork } from "@wagmi/core"
import { toast } from "react-toastify";
import { SyncLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { BASE_URL, PURCHASE_METHODS, SCANNER_V2_URL } from "../../_constant";
import { useAccount } from "wagmi";
import Subscribe from "./Subscribe_old";
import Packages from "./Packages";
import Success from "./Success";

const SubscriptionRegister = () => {
  const account = useAccount();
  const [isRegistered, setIsRegistered] = useState(false);
  const [loading, setLoading] = useState(true);

  const checkRegister = async () => {
    try {
      let bodyData = { address: account?.address };
      const { data } = await checkRegisterAction(bodyData);
      setIsRegistered(data?.data?.status === true);
    } catch (err) {
      // Handle errors if needed
    } finally {
      setLoading(false);
    }
  };
  const userLoginAndRedirect = async () => {
    try {
      if (account?.isConnected) {
        setLoading(true);
        const bodyData = { address: account?.address }
        const response = await usersLoginServieAction(bodyData)
        if (response?.data?.access_token) {
          window.location.href = SCANNER_V2_URL + `/dashboard?user=${response?.data?.access_token}`;
        }
      }
    } catch (err) {
      // Handle errors if needed
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    checkRegister();
  }, [account?.address]);

  useEffect(() => {
    if (isRegistered) {
      userLoginAndRedirect();
    }

  }, [isRegistered])

  return (
    <div>
      {loading ? (
        <Loader />) : <RegisterSubscription checkRegister={checkRegister} />
        // ) : isRegistered ? (
        //   <Packages />
        //   // <Success/>
        // ) : (
        //   <RegisterSubscription checkRegister={checkRegister} />
        // )
      }
    </div>
  );
};


const RegisterSubscription = ({ checkRegister }) => {
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(15);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const validateAddress = (address) => {
    const addressRegex = /^(0x)?[0-9a-fA-F]{40}$/;
    return addressRegex.test(address);
  };

  const startTimer = () => {
    setIsButtonDisabled(true);
    setTimer(15);

    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
    }, 1000);

    setTimeout(() => {
      clearInterval(interval);
      setIsButtonDisabled(false);
    }, 15000);
  };

  useEffect(() => {
    if (timer > 0 && isButtonDisabled) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1 + 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [timer, isButtonDisabled]);


  const subscriptionCreateSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email('Invalid email'),
    // referrel: Yup.string().optional(),
    referrel: Yup.string().required("Referral username is required"),
    full_name: Yup.string().required("Name is required")
  });

  const account = getAccount()

  const [isOptSent, _optSent] = useState(false)
  const [sending, _sending] = useState()
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
      referrel: '',
      address: account?.address,
      full_name: ''
    },
    validationSchema: subscriptionCreateSchema,
    onSubmit: async (data) => {
      try {

        if (!account.isConnected) {
          return toast.error('Pleased connect with your wallet!')
        }
        _sending(true)
        let bodyData = { email: data?.email, referrel: data?.referrel, address: data?.address, full_name: data.full_name }
        const response = await sendOTPSubscAction(bodyData)
        if (response) {
          _optSent(true)
          toast.success(response?.data?.message)
          startTimer();
        }
      } catch (err) {
      } finally {
        _sending(false)
      }

    },
  });


  const [registering, _registering] = useState(false)
  const handleRegistration = async () => {
    try {

      if (!account?.isConnected) {
        return toast.error('Please connect with your wallet!')
      }

      if (formik.values.email == "") {
        return toast.error("Please enter email!", { toastId: 'peeTid' })
      }
      if (!formik.values.full_name || formik.values.full_name == "") {
        return toast.error('Name is required!', { toastId: 'NameRTid' })
      }

      if (!formik.values.referrel || formik.values.referrel == "") {
        return toast.error('Please enter referral username!', { toastId: 'rfNRTid' })
      }

      if (!otp || otp == "") {
        return toast.error('Please enter OTP!', { toastId: 'peoTid' })
      }



      _registering(true)
      let bodyData = { email: formik.values.email, otp: otp, address: account?.address, referrel: formik.values.referrel, full_name: formik.values.full_name }
      const { data } = await subscriptionRegisterAction(bodyData)
      toast.success(data?.data?.message || 'Resigration successfull!', { toastId: 'rsTid' })
      checkRegister()
      if (data?.status == false) {
        return toast.error('Invalid OTP!', { toastId: 'invalidOtpTid' })
      }
    } catch (error) {
    } finally {
      _registering(false)
    }

  }


  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-xxl-7 col-lg-10 col-md-12 mx-auto">
            <div className="row mt-5">
              <form id="approve_token_form">
                <div className="row">
                  <div className="col-md-12 mx-auto">
                    <div className="row">
                      <div className="col-md-12 mx-auto">
                        <div className="approve_token_section">
                          <h3 className="text-center">Update user information</h3>
                          <div className="approve_token_card mt-2 text-start">
                            <div >
                              <label className="fieldlabels">Email</label>
                              <input
                                type="text"
                                style={{ border: " 1px solid #90a3b74d" }}
                                name="email"
                                onChange={formik.handleChange}
                                value={formik.values.email}
                                disabled={isButtonDisabled}
                                placeholder="Enter Email"
                                className={`token_address_input mt-1 ${formik.errors.email && formik.touched.email && 'invalid'}`}
                              />
                              {formik.errors.email &&
                                formik.touched.email ? (
                                <span className="text-danger">
                                  {formik.errors.email}
                                </span>
                              ) : null}
                            </div>

                            <div className="mt-2">
                              <label className="fieldlabels">Name</label>
                              <input
                                type="text"
                                name="full_name"
                                style={{ border: " 1px solid #90a3b74d" }}
                                className={`${formik.errors.full_name && formik.touched.full_name && 'invalid'}`}
                                onChange={formik.handleChange}
                                value={formik.values.full_name}
                                disabled={isButtonDisabled || sending}
                                placeholder="Enter name"
                              />
                              {formik.errors.full_name &&
                                formik.touched.full_name ? (
                                <span className="text-danger">
                                  {formik.errors.full_name}
                                </span>
                              ) : null}
                            </div>

                            <div className="row mt-2">

                              <div className="col-sm-6 col-12">
                                <label className="fieldlabels">Referral username</label>
                                <input
                                  type="text"
                                  style={{ border: " 1px solid #90a3b74d" }}
                                  className={`${formik.errors.referrel && formik.touched.referrel && 'invalid'}`}
                                  name="referrel"
                                  placeholder="Referral username"
                                  onChange={formik.handleChange}
                                  value={formik.values.referrel}
                                  disabled={isButtonDisabled || sending}
                                />
                                {formik.errors.referrel &&
                                  formik.touched.referrel ? (
                                  <span className="text-danger">
                                    {formik.errors.referrel}
                                  </span>
                                ) : null}
                              </div>

                              <div className="col-sm-6 col-12">
                                <label className="fieldlabels">Enter OTP</label>
                                <div className="d-flex align-items-center update_user_information_otp_btn">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      formik.handleSubmit();
                                    }}
                                    disabled={isButtonDisabled || sending}
                                    className={`btn btn-primary`}
                                    style={{ whiteSpace: 'nowrap' }}
                                  >
                                    {sending ? `Sending...` : isButtonDisabled ? `Resend OTP in ${timer}s` : "Send OTP"}
                                  </button>
                                  <div className="col"> {/* Adjusted size with col class */}
                                    <input
                                      type="number"
                                      style={{ border: " 1px solid #90a3b74d" }}
                                      name="otp"
                                      placeholder="Enter OTP"
                                      value={otp}
                                      onChange={(e) => setOtp(e.target.value)}
                                      className={`token_address_input mt-1`}
                                    />
                                  </div>
                                </div>
                              </div>

                            </div>

                            {/* <div className="mt-2">
                              <label className="fieldlabels">Referrer wallet address</label>
                              <input
                                type="text"
                                style={{ border: " 1px solid #90a3b74d" }}
                                name="referrel"
                                onChange={formik.handleChange}
                                value={formik.values.referrel}
                                disabled={isButtonDisabled || sending}
                                placeholder="Referrer wallet address"
                                className={`token_address_input mt-1 ${formik.errors.referrel && formik.touched.referrel && 'invalid'}`}
                              />
                              {formik.errors.referrel &&
                                formik.touched.referrel ? (
                                <span className="text-danger">
                                  {formik.errors.referrel}
                                </span>
                              ) : null}
                            </div> */}

                            {/* OTP Input and Send OTP Button */}
                            {/* <div className="d-flex align-items-center mt-2">
                              <button
                                type="button"
                                onClick={() => {
                                  formik.handleSubmit();
                                }}
                                disabled={isButtonDisabled || sending}
                                className={`btn btn-primary me-2`}
                                style={{ whiteSpace: 'nowrap', width: '150px' }}
                              >
                                {sending ? `Sending...` : isButtonDisabled ? `Resend OTP in ${timer}s` : "Send OTP"}
                              </button>
                              <div className="col"> 
                                <input
                                  type="number"
                                  style={{ border: " 1px solid #90a3b74d" }}
                                  name="otp"
                                  placeholder="Enter OTP"
                                  value={otp}
                                  onChange={(e) => setOtp(e.target.value)}
                                  className={`token_address_input mt-1`}
                                />
                              </div>
                            </div> */}

                            <div className="token_details_section_bottom">
                              <section>
                                {
                                  registering ?
                                    <div className="text-center auto mt-4 mb-3">
                                      <SyncLoader color="#3498DB" size={10} />
                                    </div>
                                    :
                                    <button
                                      className="btn align-items-center d-flex mx-auto mt-4 mb-1"
                                      type="button"
                                      style={{ textAlign: 'center' }}
                                      disabled={!isOptSent}
                                      onClick={() => {
                                        handleRegistration()
                                      }}
                                    >
                                      Submit
                                    </button>
                                }
                              </section>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}





const Loader = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <SyncLoader color="#3498DB" />
    </div>
  )
}




export default SubscriptionRegister;
