import React, { useEffect, useMemo, useState } from "react";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal, Web3Button, useWeb3Modal } from "@web3modal/react";
import { configureChains, createClient, WagmiConfig, useAccount, useNetwork } from "wagmi";
import { arbitrum, polygon, dogechain, bscTestnet, polygonMumbai, bsc } from "wagmi/chains";
import { INFURAID, createMenusLeft, createMenusRight, networkImages, BASE_URL, SCANNER_V2_URL } from "../../_constant";
import { Link, useNavigate } from "react-router-dom";
import { switchNetwork } from '@wagmi/core'
import { getNetwork } from '@wagmi/core'
import { checkRegisterAction, checkRegisterV2Action, checkSubscriptionAction, usersLoginServieAction } from "../../redux/apiActions/api.action";
import { useDispatch } from "react-redux";
import useServiceContextHook from "../../hooks/service.context.hook";
import * as userSlice from "../../redux/reducers/user";


const chains = [bscTestnet, polygon];
const projectId = INFURAID

// const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
// const wagmiClient = createClient({
//   autoConnect: true,
//   connectors: w3mConnectors({ projectId, version: 1, chains }),
//   provider,
// });
// const ethereumClient = new EthereumClient(wagmiClient, chains);



export default function Header() {

  const { isDisconnected, isConnected } = useAccount()

  const [currentNetwork, _currentNetwork] = useState()


  const [acceptChains, _acceptChains] = useState()

  const { chain, chains } = getNetwork()


  const { chain: current_chain } = useNetwork();

  useEffect(() => {
    _acceptChains(chains)
    let ethClient = chain
    _acceptChains(ethClient)
  }, [currentNetwork, isDisconnected, isConnected, current_chain])

  // show create states
  const [showCreate, setShowCreate] = useState(false)
  const myFunctionCreate = () => {
    setShowCreate(!showCreate)
  }



  const [isConfirm, _isConfirm] = useState({ id: "", isConfirming: false })
  const handleSwitchNetwork = async (data) => {
    try {
      if (chain?.id != data && isConfirm.isConfirming == false) {
        _isConfirm({ id: data, isConfirming: true })
        const network = await switchNetwork({
          chainId: data,
        })
        const closeButton = document.getElementById('btn-close-switch-network');
        closeButton.click();
        _currentNetwork(network?.id)
        _isConfirm({ id: '', isConfirming: false })
      }
    } catch (error) {
      _isConfirm({ id: '', isConfirming: false })
    }
  };

  const Walletconnect = useMemo(() => {
    return (
      //     <>
      //       <WagmiConfig client={wagmiClient}>
      <HomePage />
      //       </WagmiConfig>

      //       <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
      //     </>
    );
  });


  const switchNetworkModel = useMemo(() => {
    if (chain?.id !== undefined) {
      if (chain?.id == bsc.id || chain?.id == polygon.id) {
        return (
          <>
            <a
              className="align-items-center d-flex binance_smart_chain modal-open desktop_binance_smart_chain"
              href="#modal"
              data-bs-toggle="modal"
              data-bs-target="#switchNetworksModal"
            >
              <img
                src={networkImages[chain?.network]}
                alt="binance_logo"
                className="img-fluid me-2"
                width={20}
              />
              {chain?.name}
            </a>
            <a
              className="align-items-center d-block d-sm-none modal-open mobile_binance_smart_chain"
              href="#modal"
              data-bs-toggle="modal"
              data-bs-target="#switchNetworksModal"
            >
              <img
                src={networkImages[chain?.network]}
                alt="binance_logo"
                className="img-fluid me-2"
                width={20}
              />

            </a>
          </>

        );
      } else {
        return (
          <a
            href="#modal"
            data-bs-toggle="modal"
            data-bs-target="#switchNetworksModal"
            className="align-items-center d-flex dropbtn  modal-open"
            style={{ backgroundColor: '#e74c3c' }}
          >
            <div className="d-flex">
              <img src={`${BASE_URL}images/icon/warning.png`} alt="Create" className="img-fluid me-2" />
              <span className="warning-text">Wrong Network</span>
            </div>
          </a>
        );
      }
    }
  }, [acceptChains, networkImages, isDisconnected, isConnected]);

  function closeModal() {
    const closeButton = document.querySelector('.btn-close');
    closeButton.click();
  }

  const navigate = useNavigate();


  const [subsDetails, _subsDetails] = useState()
  const checkSubscriptionAndRedirect = async () => {
    let data = { address: account?.address }
    const response = await checkRegisterAction(data)
    _subsDetails(response?.data?.data)
    const status = response?.data?.data?.status;
    // if (account.isConnected && !status) {
    //   navigate(BASE_URL + 'subscription');
    // }
  }

  const account = useAccount()
  useEffect(() => {
    checkSubscriptionAndRedirect()
  }, [account.isConnected, account?.address]);

  const dispatch = useDispatch()

  const { isHalf } = useServiceContextHook();
  const onToggleChange = async () => {
    await dispatch(
      userSlice.isHalf(!isHalf)
    );
  }


  const [isRegisteredV2, _isRegisteredV2] = useState(false)
  const checkRegisterV2Func = async () => {
    if (account?.isConnected && account?.address) {
      const bodyData = { address: account?.address }
      const response = await checkRegisterV2Action(bodyData)
      _isRegisteredV2(response?.data?.status)
    }
  }



  useEffect(() => {
    checkRegisterV2Func()
  }, [account?.isConnected, account?.address])


  const userLoginAndRedirect = async () => {
    if (isConnected) {
      const bodyData = { address: account?.address }
      const response = await usersLoginServieAction(bodyData)
      if (response?.data?.access_token) {
        window.open(SCANNER_V2_URL + `/dashboard?user=${response?.data?.access_token}`);
      }
    }
  }

  const [checkingReg, _checkingReg] = useState(false)
  const userLoginAndRedirectMobile = async () => {
    // if (isConnected) {
    try {
      _checkingReg(true)
      const bodyData = { address: account?.address }
      const responseData = await checkRegisterAction(bodyData);
      const status = responseData?.data?.data?.status;
      if (!status) {
        navigate(BASE_URL + 'subscription')
      }
      const response = await usersLoginServieAction(bodyData)
      if (response?.data?.access_token) {
        window.location.href = SCANNER_V2_URL + `/dashboard?user=${response?.data?.access_token}`;
      }
    } catch (err) {

    } finally {
      _checkingReg(false)
    }
    // }
  }

  return (
    <>
      <nav className="navbar navbar-default py-0">
        <div className="container-fluid flex-nowrap ps-0">
          {/* Brand and toggle get grouped for better mobile display */}
          <div className="navbar-header d-flex align-items-center gap-2">
            <a
              href="javascript:void(0);"
              className={`nav_menu_btn fw-lighter side-bar ${isHalf ? 'nav_menu_func' : ''}`}
            >
              <i className="fa fa-bars ion-lg m-0" onClick={() => { onToggleChange() }} />
            </a>
            {/* <a href="javascript:void(0);"><i class="fa fa-bars ion-sm m-0"></i></a> */}
            <Link to={BASE_URL} className="d-none d-sm-block">
              <img src={`${BASE_URL}images/logo.png`} alt="images" />
            </Link>
          </div>
          {/* ======== Header Begin ======== */}
          <header className="w-100">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 d-flex flex-wrap justify-content-lg-between justify-content-end px-3">
                  <div className="header_left_btn header_top_btn d-lg-flex d-none flex-wrap">
                    {/* Upcoming */}
                    <a
                      href={BASE_URL + 'home'}
                      className="align-items-center d-flex"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(BASE_URL + 'home')
                      }}
                    >
                      <img
                        src={`${BASE_URL}images/icon/home.png`}
                        alt="home"
                        className="img-fluid me-2"
                      />
                      Home
                    </a>
                    <a
                      href={BASE_URL + 'subscription'}
                      onClick={(e) => {
                        if (isConnected) {
                          e.preventDefault();
                          userLoginAndRedirectMobile();
                        } else {
                          // e.preventDefault();
                          navigate(BASE_URL + 'subscription')
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                      className="align-items-center d-flex"
                    >
                      <i className="fa fa-usd me-2" />
                      Go to scanner Dashboard
                    </a>

                    {
                      // (isRegisteredV2 && account?.isConnected) &&
                      // <a
                      //   href={BASE_URL + 'subscription'}
                      //   onClick={(e) => {
                      //     e.preventDefault();
                      //     userLoginAndRedirect()
                      //     // window.open(SCANNER_V2_URL+'/dashboard?user=0d1bd7854238bef3722109d30fc01b7792feabe3c738d6074ebbc81efebfd3be1673269437', '_blank');
                      //   }}
                      //   style={{ cursor: 'pointer' }}
                      //   className="align-items-center d-flex"
                      // >
                      //   {/* <i className="fa fa-usd me-2" /> */}
                      //   Go to scanner Dashboard
                      // </a>
                    }


                    {/* <a
                      href="javascript:void(0);"
                      className="align-items-center d-flex"
                    >
                      <img
                        src="images/icon/docs.png"
                        alt="Docs"
                        className="img-fluid me-2"
                      />
                      Docs
                    </a> */}
                    {/* <a
                      href="javascript:void(0);"
                      className="align-items-center d-flex"
                    >
                      <img
                        src="images/icon/presale-alerts.png"
                        alt="Presale Alerts"
                        className="img-fluid me-2"
                      />
                      Presale Alerts
                    </a> */}
                  </div>
                  {/* <div className="header_right_btn header_top_btn d-flex flex-wrap justify-content-end align-items-center"> */}
                  <div className="header_right_btn header_top_btn d-flex flex-wrap justify-content-end">

                    {/* <a
                      href="#modal"
                      data-bs-toggle="modal"
                      data-bs-target="#createModal"
                      className="align-items-center d-flex dropbtn  modal-open create_model_btn_pop">
                      <div className="d-flex align-items-center">
                        <img src={`${BASE_URL}images/icon/create.png`} alt="Create" className="img-fluid me-sm-2 me-0" />
                        <span className="d-none d-sm-block">Create</span>
                      </div>
                    </a> */}
                    {
                      // (isRegisteredV2 && account?.isConnected) &&
                      // <button data-toggle="tooltip" data-placement="bottom" title="Subscribe Now" className="btn btn-primary btn-floating text-center subscribe_now_bnt subscribe_now_bnt_icon d-block d-sm-none " style={{ whiteSpace: 'nowrap' }}
                      //   disabled={checkingReg}
                      //   onClick={() => {
                      //     userLoginAndRedirectMobile()
                      //   }}>
                      //   <img src={`${BASE_URL}images/icon/dashboard.svg`} alt="subscribe now" className="img-fluid" />
                      // </button>
                    }

                    <button data-toggle="tooltip" data-placement="bottom" title="Subscribe Now" className="btn btn-primary btn-floating text-center subscribe_now_bnt subscribe_now_bnt_icon d-block d-sm-none " style={{ whiteSpace: 'nowrap' }} onClick={(e) => {
                      if (isConnected) {
                        e.preventDefault();
                        userLoginAndRedirectMobile();
                      } else {
                        // e.preventDefault();
                        navigate(BASE_URL + 'subscription')
                      }
                    }}>
                      <img src={`${BASE_URL}images/icon/subscribe_now.svg`} alt="subscribe now" className="img-fluid" />
                    </button>
                    <a
                      href="#modal"
                      data-bs-toggle="modal"
                      data-bs-target="#createModal"
                      className="align-items-center dropbtn  modal-open d-flex create_model_open_btn">
                      <div className="d-flex align-items-center">
                        <img src={`${BASE_URL}images/icon/create.png`} alt="Create" className="img-fluid me-sm-2 me-0" />
                        <span className="d-none d-sm-block">Create</span>
                      </div>
                    </a>
                    <div id="myDropdown"
                      // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                      className="dropdown-content px-2 py-4"
                    >
                      <h2 className="text-center text-blue mb-4">Create</h2>
                      <div className="row row-cols-1 row-cols-sm-2">
                        <div className="col">
                          <ul className="gap-3 d-grid">
                            {Object.keys(createMenusLeft).map((value, key) => (
                              <li key={value}><Link to={createMenusLeft[value]} aria-disabled={true}>{value}</Link></li>
                            ))}
                          </ul>
                        </div>
                        <div className="col">
                          <ul className="gap-3 d-grid">
                            {Object.keys(createMenusRight).map((value, key) => (
                              <li key={value}><Link to={createMenusRight[value]}>{value}</Link></li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    {/* <a href="javascript:void(0);" class="align-items-center d-md-flex d-none"><img src="images/icon/home.png" alt="Create" class="img-fluid me-2">Create</a> */}

                    {/* {
                    acceptChains?.chain!=undefined &&
                    <a className="align-items-center d-flex binance_smart_chain modal-open" href="#modal" data-bs-toggle="modal" data-bs-target="#switchNetworksModal"><img src={networkImages[acceptChains.chain.network]} alt="binance_logo" className="img-fluid me-2" width={20} />{acceptChains?.chain?.name}</a>
                   } */}
                    {switchNetworkModel}
                    {
                      subsDetails?.status == true &&
                      <h6 className="me-2 ref_user_name_heading d-none d-lg-block">Username : {subsDetails?.username}</h6>
                    }

                    {/* <button className="btn btn-primery btn-connect">{Walletconnect}</button> */}
                    {Walletconnect}
                  </div>

                </div>
              </div>
            </div>
          </header>
          {/* ======== Header End ======== */}


          {/* ?create popup */}
          <div className="modal" id="createModal">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* Modal Header */}
                {/* <div className="modal-header"> */}
                {/* <h5 className="modal-title text-white">Switch Networks</h5> */}
                <button type="button" className="btn-close" data-bs-dismiss="modal" hidden={true} />
                {/* </div> */}
                {/* Modal body */}
                <div className="modal-body">
                  <div id="myDropdown"
                    // className={`dropdown-content px-2 py-4 ${showCreate ? 'show' : 'hide'}`}
                    className="dropdown-content px-2 py-4"
                  >
                    <h2 className="text-center text-blue mb-4">Create</h2>
                    <div className="row row-cols-1 row-cols-sm-2">
                      <div className="col">
                        <ul className="gap-3 d-grid create_model_anchor mb-3">
                          {Object.keys(createMenusLeft).map((value, key) => (
                            <li className="create_button" key={value}><Link to={createMenusLeft[value]} aria-disabled={true} onClick={() => closeModal()}>{value}</Link></li>
                          ))}
                        </ul>
                      </div>
                      <div className="col">
                        <ul className="gap-3 d-grid create_model_anchor">
                          {Object.keys(createMenusRight).map((value, key) => (
                            <li className="create_button" key={value}><Link to={createMenusRight[value]} onClick={() => closeModal()}>{value}</Link></li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>

          {/* ?end */}
          {/* The Modal */}
          <div className="modal" id="switchNetworksModal">
            <div className="modal-dialog">
              <div className="modal-content">
                {/* Modal Header */}
                <div className="modal-header">
                  <h5 className="modal-title text-white">Switch Networks</h5>
                  <button type="button" className="btn-close" id="btn-close-switch-network" data-bs-dismiss="modal" />
                </div>
                {/* Modal body */}
                <div className="modal-body">
                  <ul className="gap-2 d-grid">
                    {
                      chains && chains?.map((data) => {
                        return (
                          <li key={data?.id} style={{ cursor: 'pointer' }}>
                            <a href className={`d-flex align-items-center justify-content-between ${data?.id == chain?.id ? 'active' : ''}`} onClick={() => { handleSwitchNetwork(data?.id) }}>
                              <div className="d-flex align-items-center">
                                <img src={networkImages[data.network]} className="img-fluid me-2" alt="icon" width={28} />
                                <h6 className="text-white">{data?.name}</h6>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                {data?.id == chain?.id
                                  ?
                                  <>
                                    <span className={`connected-status`} />
                                    <p className="mb-0 text-white">{'Connected'}</p>
                                  </>
                                  : ''
                                }
                                {
                                  isConfirm['isConfirming'] ?
                                    <>
                                      <span className={isConfirm['id'] == data.id && 'confirm-wallet-status'} />
                                      <p className="mb-0 text-white">{isConfirm['id'] == data.id && 'Confirm in your wallet'}</p>
                                    </>
                                    : ''
                                }

                              </div>
                            </a>
                          </li>
                        )
                      })
                    }

                    {/* <li>
                      <a href className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                          <h6 className="text-white">BNB Smart Chain</h6>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0 text-white">Confirm in Wallet</p>
                          <span className="confirm-wallet-status" />
                        </div>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href className="d-flex align-items-center justify-content-between" onClick={() => handleChangeNetwork(2000)}>
                        <div className="d-flex align-items-center">
                          <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                          <h6 className="text-white">Arbitrum One</h6>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <span className="connected-status" />
                        </div>
                      </a>
                    </li> */}
                    {/* <li>
                      <a href className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <img src="images/icon/binance_logo.png" className="img-fluid me-2" alt="icon" width={28} />
                          <h6 className="text-white">Core DAO</h6>
                        </div>
                        <div className="d-flex align-items-center gap-2">
                          <span className="connected-status" />
                        </div>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* /.container-fluid */}
      </nav>

      <div className="side-bar-overlay"></div>
    </>
  );
}

function HomePage() {
  // return <Web3Button />;
  const { isDisconnected, isConnected, address, isConnecting } = useAccount();
  const { open, close, isOpen } = useWeb3Modal()

  return (
    <>
      <button className="btn btn-primery btn-connect d-none d-sm-block" disabled={isOpen} onClick={() => { open() }}>{isOpen && !isConnected ? 'Connecting...' : isConnected ? `${address.slice(0, 4)}...${address.slice(-4)}` : 'Connect'}</button>
      <button className="btn btn-primery btn-connect d-block d-sm-none" disabled={isOpen} onClick={() => { open() }}>{isOpen && !isConnected ? 'Connecting...' : isConnected ?
        <img src={`${BASE_URL}images/icon/wallet.svg`} alt="Create" className="img-fluid me-sm-2 me-0" />
        : 'Connect'}</button>
    </>
  )

}
