/// Mainnet Chains
// import { arbitrum, mainnet, polygon, bsc } from "wagmi/chains";
// const chains = [arbitrum, mainnet, polygon, bsc];

/// Testnet Chains
import { bscTestnet,arbitrum,polygonMumbai,dogechain, bsc, polygon } from "wagmi/chains";
import { INFURAID } from "../../_constant";
export const chains = [bsc,polygon];

export const PROJECT_ID = INFURAID;
