import React,{createContext, useMemo, useState} from "react";
import {useSelector} from "react-redux"

export const ServiceContext=createContext()

export default function ServiceContextProvider(props){
    const {myCWallet,redrumConnectorId,redrumWallet,isHalf} = useSelector((state)=>state.user);

    const ServiceContextValue=useMemo(()=>({
        myCWallet:myCWallet,
        redrumConnectorId:redrumConnectorId,
        redrumWallet:redrumWallet,
        isHalf:isHalf
    }),[myCWallet,isHalf])


    return(
        <ServiceContext.Provider
        value={ServiceContextValue}
        >
        {props.children}
        </ServiceContext.Provider>
    )

}