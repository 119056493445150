import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Landing from "../../Components/Landing";
import Layout from "../../Components/Layouts/layout";
import Presale from "../../Pages/Presale/Presale";
import Special from "../../Pages/Special/Special";
import CreateToken from "../../Components/CreateToken/CreateToken";
import { BASE_URL } from "../../_constant";
import CreatePrescale from "../../Pages/CreatePrescale/CreatePrescale";
import PresaleDetails from "../../Pages/PresaleDetails/PresaleDetails";
import Airdrop from "../../Pages/Airdrop/Airdrop";
import Calculator from "../../Pages/Calculator/Calculator";
import CreateFairLaunch from "../../Pages/CreatePrescale/CreateFairLaunch";
import CreateHypersLaunch from "../../Pages/CreatePrescale/CreateHyperLaunch";
import CreateLock from "../../Pages/CreateLock/CreateLock";
import TokenLocks from "../../Pages/TokenLocks/TokenLocks";
import TokenLockDetails from "../../Pages/TokenLocks/TokenLockDetails";
import LiquidityLocks from "../../Pages/LiquidityLocks/LiquidityLock";
import LiquidityLockDetails from "../../Pages/LiquidityLocks/LiquidityLockDetail";
import CreateStack from "../../Pages/CreatePrescale/CreateStack";
import StackingPool from "../../Pages/StackingPool/StackingPool";
import Home from "../../Pages/Home/Home";
import StackDetails from "../../Pages/StackingPool/StackDetails";
import NotFound from "../../Pages/NotFound/NotFound";
import Loader from "../../Components/Loader";
import CreateSpecial from "../../Pages/CreatePrescale/CreateSpecial";
import SpecialDetails from "../../Pages/SpecialDetails/SpecialDetails";
import SubscriptionRegister from "../../Pages/Subscription/SubRegister";
import Packages from "../../Pages/Subscription/Packages";
import Payment from "../../Pages/Subscription/Payment";
import SubCashback from "../../Pages/SubsCashback/SubCashback";
import ReferralsCashback from "../../Pages/ReferralCashback/RefferCashback";
import SubsDetails from "../../Pages/SubsCashback/SubscDetail";
import Approve from "../../Pages/Subscription/Approved";
{/* <Route exact path={BASE_URL.BASE_URL}>
<Markets socket={socket} />
</Route> */}

// const Presale  =  React.lazy(() => import('../../Pages/Presale/Presale'));

const PublicRoutes = ({ notificationToken }) => {
  // console.log(notificationToken, "PublicRoutes notificationToken")
  return (
    <>
      <Layout>
        <Routes>
          <Route path={BASE_URL} exact={true} element={<Navigate to={BASE_URL + `presales?page=1&filterBy=all&sortBy=no&networks=97&tab=all_products`} replace />} />
          <Route path={BASE_URL + `specials`} exact={true} element={<Special  notificationToken={notificationToken}/>} />
          <Route path={BASE_URL + 'create-token'} exact={true} element={<CreateToken />} />
          <Route path={BASE_URL + 'presales'} exact={true} element={
            
            // <React.Suspense fallback={<Loader/>}>
            <Presale notificationToken={notificationToken} />
      // </React.Suspense>
        } 
          
          />
          <Route path={BASE_URL + 'create-presale'} exact={true} element={<CreatePrescale />} />
          <Route path={BASE_URL + 'presale'} exact={true} element={<PresaleDetails />} />
          <Route path={BASE_URL + 'special'} exact={true} element={<SpecialDetails />} />
          <Route path={BASE_URL + 'airdrop'} exact={true} element={<Airdrop />} />
          <Route path={BASE_URL + 'presale-calculator'} exact={true} element={<Calculator />} />
          <Route path={BASE_URL + 'create-fair-launch'} exact={true} element={<CreateFairLaunch />} />
          <Route path={BASE_URL + 'create-hyper-launch'} exact={true} element={<CreateHypersLaunch />} />
          <Route path={BASE_URL + 'create-lock'} exact={true} element={<CreateLock />} />
          <Route path={BASE_URL + 'token-locks'} exact={true} element={<TokenLocks />} />
          <Route path={BASE_URL + 'token-lock-detail'} exact={true} element={<TokenLockDetails />} />
          <Route path={BASE_URL + 'create-stake'} exact={true} element={<CreateStack />} />
          <Route path={BASE_URL + 'liquidity-locks'} exact={true} element={<LiquidityLocks />} />
          <Route path={BASE_URL + 'liquidity-lock-detail'} exact={true} element={<LiquidityLockDetails />} />
          <Route path={BASE_URL + 'stakes'} exact={true} element={<StackingPool />} />
          <Route path={BASE_URL + 'home'} exact={true} element={<Home />} />
          <Route path={BASE_URL + 'stake'} exact={true} element={<StackDetails/>}/>
          <Route path={BASE_URL + 'create-special'} exact={true} element={<CreateSpecial/>}/>
          <Route path={BASE_URL + 'create-special'} exact={true} element={<CreateSpecial/>}/>
          <Route path={BASE_URL + 'subscription'} element={<SubscriptionRegister />} />
          <Route path={BASE_URL + 'packages'} element={<Packages />} />
          <Route path={BASE_URL + 'make-payment'} element={<Payment />} />
          <Route path={BASE_URL + 'subsc-cashback'} element={<SubCashback />} />
          <Route path={BASE_URL + 'refferal-cashback'} element={<ReferralsCashback />} />
          <Route path={BASE_URL + 'subs-details'} element={<SubsDetails />} />
          <Route path={BASE_URL + 'approve'} element={<Approve />} />
          <Route path={BASE_URL + '404'} element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </>
  );
};
export default PublicRoutes;
