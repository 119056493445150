import React, {useEffect} from 'react'
import Sidebar from '../sidebar/Sidebar';
import Header from '../header/header';
import useServiceContextHook from '../../hooks/service.context.hook';
// import Footer from "../footer/footer";   

export default function Layout(props) {
useEffect(() => {
let url = window.location.href
},[])
const { isHalf } = useServiceContextHook();

  return (
    <>
      <Header />
      <Sidebar/>
      {/* <Sidebar/> */}
      <div className={`body-content side-bar ${isHalf ? 'side-bar-half':''}`}>
      {props.children}
      </div>
      {/* <Footer /> */}
    </>
  );
}
