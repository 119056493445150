import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../../_constant";
import { checkRegisterAction, checkSubscriptionAction } from "../../redux/apiActions/api.action";
import { configureChains, createClient, WagmiConfig, useAccount, useNetwork } from "wagmi";
import useServiceContextHook from "../../hooks/service.context.hook";

export default function Sidebar() {
  const account = useAccount()
  const [subsDetails,_subsDetails] = useState()
  const checkSubscriptionAndRedirect = async()=>{
    let data = {address:account?.address}
    const response = await checkRegisterAction(data)
    _subsDetails(response?.data?.data)
    const status = response?.data?.data?.status;
  }

    
    useEffect(() => {
      checkSubscriptionAndRedirect()
    }, [account.isConnected,account?.address]);

    const { isHalf } = useServiceContextHook();

  return (
    <div className={`nav-sidebar side-bar ${isHalf ? 'side-bar-half':''}`}>
      {/* <div class="brand-logo d-block d-sm-none">
  <a href="index.html"><img src="images/logo.png" class="img-fluid" alt="logo"></a>
    </div> */}
      <div className="sidebar list-group">
      {/* <li> */}
      {
             subsDetails?.status == true &&
          <h6 className="mb-2 ref_user_name_heading d-block d-lg-none ms-4">Username :  {subsDetails?.username}</h6>
      }
            {/* </li> */}
        <h6 className="text-white list-group-item mb-0">
          <img
            src={BASE_URL+`images/dots.svg`}
            alt="dots"
            className="explore_dot mx-auto"
          />{" "}
          <span>EXPLORE</span>
        </h6>
        <ul className="list-group pt-0">
          
  <li className="list-group-item">
    <NavLink to={BASE_URL+`presales`} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Presales" />
        <img src={BASE_URL+`images/nav-icon/1.svg`} className="img-fluid" alt="presales" />
      </span>
      <p>Presales</p>
    </NavLink>
  </li>
  <li className="list-group-item">
    <NavLink to={BASE_URL+"specials"} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Special Sales" />
        <img src="images/nav-icon/2.svg" className="img-fluid" alt="special sales" />
      </span>
      <p>Special Sales</p>
    </NavLink>
  </li>
  <li className="list-group-item">
  <NavLink to={BASE_URL+`stakes`} activeClassName="active">
      <span className="sidebar_icon_p">
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Staking Pools" />
        <img src={BASE_URL+`images/nav-icon/3.svg`} className="img-fluid" alt="staking pools" />
      </span>
      <p>Staking Pools</p>
    </NavLink>
  </li>
  <li className="list-group-item">
  <NavLink to={`${BASE_URL}token-locks`} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Token Locks" />
        <img src={BASE_URL+"images/nav-icon/4.svg"} className="img-fluid" alt="token locks" />
      </span>
      <p>Token Locks</p>
    </NavLink>
  </li>
  <li className="list-group-item">
    <NavLink to={`${BASE_URL}liquidity-locks`} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Liquidity Locks" />
        <img src={BASE_URL+"images/nav-icon/5.svg"} className="img-fluid" alt="liquidity locks" />
      </span>
      <p>Liquidity Locks</p>
    </NavLink>
  </li>

  <li className="list-group-item">
    <NavLink to={`${BASE_URL}subsc-cashback`} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Subscription & Cashback" />
        <img src={BASE_URL+"images/nav-icon/8.svg"} className="img-fluid" alt="liquidity locks" />
      </span>
      <p>Subscriptions & Cashback</p>
    </NavLink>
  </li>

{/* 
  <li className="list-group-item">
    <NavLink to={`${BASE_URL}refferal-cashback`} activeClassName="active">
      <span>
        <div className="presales_par" id="tipRight" data-bs-toggle="tooltip" title="Subscription & Cashback" />
        <img src={BASE_URL+"images/nav-icon/9.svg"} className="img-fluid" alt="liquidity locks" />
      </span>
      <p>Referrals & Cashback</p>
    </NavLink>
  </li> */}



</ul>
        <hr />
        <h6 className="text-white list-group-item mb-0">
          <img
            src={BASE_URL+"images/dots.svg"}
            alt="dots"
            className="explore_dot mx-auto"
          />{" "}
          <span>DEVELOPERS</span>
        </h6>
        <div
          className="accordion developers_dropdown_menu"
          id="accordionExample"
        >
        <div className="list-group-item accordion-item">
  <h2 className="accordion-header" id="headingTwo">
    <button
      className="accordion-button collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseTwo"
      aria-expanded="false"
      aria-controls="collapseTwo"
    >
      <NavLink to="#" className="active" onClick={(event)=>{  event.preventDefault();}}>
        <span>
          <div
            className="presales_par"
            id="tipRight"
            data-bs-toggle="tooltip"
            title="Create & Launch"
          />
          <img
            src={BASE_URL+"images/nav-icon/6.svg"}
            className="img-fluid"
            alt="presales"
          />
        </span>{" "}
        <p>Create &amp; Launch</p>
      </NavLink>
    </button>
  </h2>
  <div
    id="collapseTwo"
    className="accordion-collapse collapse"
    aria-labelledby="headingTwo"
    data-bs-parent="#accordionExample"
  >
    <div className="accordion-body pt-0 ps-5">
      <NavLink to={`${BASE_URL}create-presale`} activeClassName="active">
        <div
          // className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Presale"
        />
        Presale
      </NavLink>
      <NavLink to={`${BASE_URL}create-hyper-launch`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Hyper Launch"
        />
        Hyper Launch
      </NavLink>
      <NavLink to={BASE_URL+`create-fair-launch`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Fair Launch"
        />
        Fair Launch
      </NavLink>
      <NavLink to={BASE_URL + `create-special`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Special Sale"
        />
        Special Sale
      </NavLink>
      <NavLink to={BASE_URL+`create-token`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Token"
        />
        Token
      </NavLink>
      <NavLink to={BASE_URL+`create-stake`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Staking Pool"
        />
        Staking Pool
      </NavLink>
      <NavLink to={BASE_URL+`create-lock`} activeClassName="active">
        <div
          className="presales_par"
          id="tipRight"
          data-bs-toggle="tooltip"
          title="Lock"
        />
        Lock
      </NavLink>
    </div>
  </div>
</div>
<div className="list-group-item accordion-item">
      <h2 className="accordion-header" id="headingThree">
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseThree"
          aria-expanded="false"
          aria-controls="collapseThree"
        >
          <NavLink to="#" activeClassName="active" onClick={(event)=>{  event.preventDefault();}}>
            <span>
              <div
                className="presales_par"
                id="tipRight"
                data-bs-toggle="tooltip"
                title="Utility & Tools"
              />
              <img
                src={BASE_URL+"images/nav-icon/7.svg"}
                className="img-fluid"
                alt="presales"
              />
            </span>{" "}
            <p>Utility &amp; Tools</p>
          </NavLink>
        </button>
      </h2>
      <div
        id="collapseThree"
        className="accordion-collapse collapse"
        aria-labelledby="headingThree"
        data-bs-parent="#accordionExample"
      >
        <div className="accordion-body pt-0 ps-5">
        <NavLink to={`${BASE_URL}airdrop`} activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="Airdrop"
            />
            Airdrop
          </NavLink>
          <NavLink to={BASE_URL+`presale-calculator`} activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="Presale Calculator"
            />
            Presale Calculator
          </NavLink>
          <NavLink to={BASE_URL+`create-token`} activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="Create Token"
            />
            Create Token
          </NavLink>
          {/* <NavLink to="/manage-antibot" activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="Manage Anti bot"
            />
            Manage Anti bot
          </NavLink> */}
          {/* <NavLink to="/gems-stake" activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="GEMs Stake"
            />
            GEMs Stake
          </NavLink> */}
          <NavLink to="https://test.multichain.org/#/router" target="_blank" activeClassName="active">
            <div
              className="presales_par"
              id="tipRight"
              data-bs-toggle="tooltip"
              title="Multichain Bridge"
            />
            Multichain Bridge
          </NavLink>
        </div>
      </div>
    </div>
        </div>
      </div>
    </div>
  );
}
